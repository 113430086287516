import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.getElementById('navbar');
      if (window.scrollY > 50) {
        navbar.classList.add('scrolled');
      } else {
        navbar.classList.remove('scrolled');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMenuClick = (event) => {
    const target = event.target.getAttribute('href');
    const offsetTop = document.querySelector(target).offsetTop;

    window.scrollTo({
      top: offsetTop - 70, // Adjust offset as needed
      behavior: 'smooth'
    });
  };

  return (
    <div className="stcky-psn" id='navbar'>
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light">
          <Link className="navbar-brand" to="/" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>Ekonnet</Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link active" to="/" onClick={handleMenuClick}>Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/buy-crypto" onClick={handleMenuClick}>Buy Crypto</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/market-overview" onClick={handleMenuClick}>Market Overview</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/future" onClick={handleMenuClick}>Future</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/traders" onClick={handleMenuClick}>Traders</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/affiliate" onClick={handleMenuClick}>Affiliate</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/" onClick={handleMenuClick}>About Us</Link>
              </li>
            </ul>
            <form className="d-flex">
              <ul className="sgn-lgn">
                <li><Link to="/login" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>Login</Link></li>
                <li><Link to="/register" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>Register</Link></li>
              </ul>
            </form>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
