import React from 'react'

const Future = () => {
  return (
    <div>
      
    </div>
  )
}

export default Future
