import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Component/Navbar'; 
import Home from "./Pages/Home";
import MarketOverview from './Pages/MarketOverview ';
import Traders from './Pages/Traders';
import Affiliate from './Pages/Affiliate';
import AboutUs from './Pages/AboutUs';
import Future from './Pages/Future';
import BuyCrypto  from './Pages/BuyCrypto';

import ForgotPassword from './Pages/ForgotPassword';
import Footer  from './Component/Footer';
import Register from './Pages/Register';
import Login from './Pages/Login';
const App = () => {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/buy-crypto' element={<BuyCrypto/>} />
          <Route path='/market-overview' element={<MarketOverview />} />
          <Route path='/future' element={<Future />} />
          <Route path='/traders' element={<Traders />} />
          <Route path='/affiliate' element={<Affiliate />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/register'element={<Register />}/>
          <Route path='/login'element={<Login />}/>
          <Route path='/forgot-password' element={<ForgotPassword />} />
          
        </Routes>
        <Footer/>
      </div>
    </Router>
  );
}
export default App;
