import React, { useState } from 'react';
import { IoMdLock } from 'react-icons/io';
import { Link } from 'react-router-dom';
import './Login.css'; 

const Login = () => {
  const [emailFocused, setEmailFocused] = useState(false);
  const [emailValue, setEmailValue] = useState('');
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [passwordValue, setPasswordValue] = useState('');

  const handleEmailFocus = () => setEmailFocused(true);
  const handleEmailBlur = () => setEmailFocused(false);
  const handleEmailChange = (e) => setEmailValue(e.target.value);

  const handlePasswordFocus = () => setPasswordFocused(true);
  const handlePasswordBlur = () => setPasswordFocused(false);
  const handlePasswordChange = (e) => setPasswordValue(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    
    console.log('Logging in...');
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <div className="lock-container" >
          <IoMdLock className="lock-icon"  />
        </div>
        <h1 style={{border:"5px sold red",color:"black"}}>Login</h1>
        <form onSubmit={handleSubmit}>
          <div className="input-container">
            <input
              type="email"
              value={emailValue}
              onChange={handleEmailChange}
              onFocus={handleEmailFocus}
              onBlur={handleEmailBlur}
              placeholder="Email Address"
              className={`input-field ${emailFocused || emailValue ? 'active' : ''}`}
            />
            <label className={`input-label ${emailFocused || emailValue ? 'focused' : ''}`}>
              Email Address
            </label>
          </div>
          <div className="input-container">
            <input
              type="password"
              value={passwordValue}
              onChange={handlePasswordChange}
              onFocus={handlePasswordFocus}
              onBlur={handlePasswordBlur}
              placeholder="Password"
              className={`input-field ${passwordFocused || passwordValue ? 'active' : ''}`}
            />
            <label className={`input-label ${passwordFocused || passwordValue ? 'focused' : ''}`}>
              Password
            </label>
          </div>
          <div className="remember-container">
            <input type="checkbox" id="rememberMe" />
            <label htmlFor="rememberMe">Remember me</label>
          </div>
          <div className="login-button-container">
            <button type="submit" className="login-button">LOGIN</button>
          </div>
          <div className="links-container">
            <Link to='/ForgotPassword' className="forgot-password">Forgot Password?</Link>
            <Link to='/register' className="sign-up">Don't have an account? Register</Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
