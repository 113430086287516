import React from 'react'

const Affiliate = () => {
  return (
    <div>
      sf
    </div>
  )
}

export default Affiliate
