import React from 'react';
import backgroundImage from '../images/backgroundImage.jpg';
import about1 from '../images/about1.png';
import './AboutUs.css'; // Import your CSS file for styling
import layer from '../images/layer.png';
import about2 from '../images/about2.png'
const AboutUs = () => {
  const divStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh',
  };

  const div1Style = {
    backgroundImage: `url(${layer})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh', // Changed to match the first section height
    width: '100%',
    
  };

  return (
    <>
      <section>
        <div style={divStyle}>
          <div className="overlay">
            <p className="about-text">About-US</p>
            <div className="image-container">
              <img className="moving-image" src={about1} alt="About Image" />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div style={div1Style}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'}}>
            <div style={{display:"flex",
    flexDirection: 'row',
    alignItems: 'center'}}>
            <img src={about2}/>
          <div style={{width:'42%'}}>
            <h2 style={{fontSize:'29px',color:"white"}}>About Ekonnet?</h2>
            <h1>We Built a Crypto Platform<br/>
            To Buy & Sell Shares</h1>
            <p style={{fontSize:"15px"}}>Progressively predominate performance based internal or<br/>
             "orga sources before high standards in architectures. <br/>
             Interactively ini transparent functionalities via efficient ROI.</p>
          </div>
            </div>
        
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
