import React from 'react';
import bit from '../images/bit.png';
import { BiDollarCircle } from "react-icons/bi";
import layer from '../images/layer.png'
import trusted from '../images/trusted.png'
import trusted1 from '../images/trusted1.png'
import trusted2 from '../images/trusted2.png'
import trusted3 from '../images/trusted3.png'
import bitcoin from '../images/bitcoin.webp';
import coin1 from '../images/coin1.webp';
import coin2 from '../images/coin2.webp';
import coin3 from '../images/coin3.webp';
import coin4 from '../images/coin4.webp';
import coin5 from '../images/coin5.webp';
import coin6 from '../images/coin6.webp';
import coin7 from '../images/coin7.webp';
import coin8 from '../images/coin8.webp';
import coin9 from '../images/coin9.webp';
import coin10 from '../images/coin10.webp';
import coin11 from '../images/coin11.webp';
import ana from '../images/ana.png';
import download from '../images/download.png';
import downloads from '../images/downloads.png';
import exch from '../images/exch.png';
import peer from '../images/peer.png';
import trade from '../images/trade.png';
import invest from '../images/invest.png';
import mobile from '../images/mobile.png';
import './Home.css';
const Home = () => {
  return (
    <div>
      <section className="first-section">
        <img className="layer-mg" src={layer} alt="Layer" />
        <div className="container">
          <div className="flex-section">
            <div className="content">
              <div className="">
                <div className="icon">
                  <span className="discover-text">
                  <BiDollarCircle   style={{background: "#00f88a",color:"black"}}/>
                    Discover new ways to enjoy the world
                  </span>
                </div>
              </div>
              <h1>
                Trade like a legend on the legendary <br />
                crypto exchange
              </h1>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Error
                quasi esse fugit modi hic quae, reprehenderit ipsam? Fugit aut
                eos rerum suscipit labore at necessitatibus dignissimos, sit
                officia error assumenda.
              </p>
              <div className="button-container">
                <button className="button primary">Get Started</button>
                <button className="button">Get Started</button>
              </div>
            </div>

            <div className="media-container">
              <div className="image-container">
                <img
                  className="image"
                  src={bit}
                  alt="Decorative"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
		<div className="container">
			<div className="we-trusted">
				<h3>We are Trusted</h3>
				<div className="row gx-5">
					<div className="col-sm-3">
						<img src={trusted}/>
					</div>
					<div className="col-sm-3">
						<img src={trusted1}/>
					</div>
					<div className="col-sm-3">
						<img src={trusted2}/>
					</div>
					<div className="col-sm-3">
						<img src={trusted3}/>
					</div>
				</div>
			</div>
		</div>
	</section>
  <section>
		<div className="container">
			<div className="coin-search">
				<h3>Coin Search</h3>
				<ul className="coin-price">
					<li>#</li>
					<li>Coin</li>
					<li>Price</li>
					<li>24h</li>
					<li>Volume</li>
					<li>Market Capital</li>
				</ul>
				<ul className="coin-price-tabel">
					<li>1</li>
					<li><img src={bitcoin} />BTC</li>
					<li>$61,650</li>
					<li>1.30%</li>
					<li>$26,936,513,606</li>
					<li>$26,936,513,606</li>
				</ul>
				<ul className="coin-price-tabel">
					<li>1</li>
					<li><img src={coin1} />ETH</li>
					<li>$61,650</li>
					<li>1.30%</li>
					<li>$26,936,513,606</li>
					<li>$26,936,513,606</li>
				</ul>
				<ul className="coin-price-tabel">
					<li>1</li>
					<li><img src={coin2}/>USDT</li>
					<li>$61,650</li>
					<li>1.30%</li>
					<li>$26,936,513,606</li>
					<li>$26,936,513,606</li>
				</ul>
				<ul className="coin-price-tabel">
					<li>1</li>
					<li><img src= {coin3}/>BNB</li>
					<li>$61,650</li>
					<li>1.30%</li>
					<li>$26,936,513,606</li>
					<li>$26,936,513,606</li>
				</ul>
				<ul className="coin-price-tabel">
					<li>1</li>
					<li><img src={coin4}/>SOL</li>
					<li>$61,650</li>
					<li>1.30%</li>
					<li>$26,936,513,606</li>
					<li>$26,936,513,606</li>
				</ul>
				<ul className="coin-price-tabel">
					<li>1</li>
					<li><img src={coin5} />USDC</li>
					<li>$61,650</li>
					<li>1.30%</li>
					<li>$26,936,513,606</li>
					<li>$26,936,513,606</li>
				</ul>	
				<ul className="coin-price-tabel">
					<li>1</li>
					<li><img src={coin6} />STETH</li>
					<li>$61,650</li>
					<li>1.30%</li>
					<li>$26,936,513,606</li>
					<li>$26,936,513,606</li>
				</ul>
				<ul className="coin-price-tabel">
					<li>1</li>
					<li><img src={coin7} />XRP</li>
					<li>$61,650</li>
					<li>1.30%</li>
					<li>$26,936,513,606</li>
					<li>$26,936,513,606</li>
				</ul>
				<ul className="coin-price-tabel">
					<li>1</li>
					<li><img src={coin8} />TON</li>
					<li>$61,650</li>
					<li>1.30%</li>
					<li>$26,936,513,606</li>
					<li>$26,936,513,606</li>
				</ul>
				<ul className="coin-price-tabel">
					<li>1</li>
					<li><img src={coin9} />DOGE</li>
					<li>$61,650</li>
					<li>1.30%</li>
					<li>$26,936,513,606</li>
					<li>$26,936,513,606</li>
				</ul>
				<ul className="coin-price-tabel">
					<li>1</li>
					<li><img src={coin10}/>ADA</li>
					<li>$61,650</li>
					<li>1.30%</li>
					<li>$26,936,513,606</li>
					<li>$26,936,513,606</li>
				</ul>
				<ul className="coin-price-tabel">
					<li>1</li>
					<li><img src={coin11} />TRX</li>
					<li>$61,650</li>
					<li>1.30%</li>
					<li>$26,936,513,606</li>
					<li>$26,936,513,606</li>
				</ul>		
			</div>
		</div>
	</section>
  <section className="trade-section">
		<div className="trade">
			<div className="container">
				<div className="row">
					<div className="col-sm-6">
						<h3>Trade</h3>
						<p>An intuitive interface for trading all your assets. Trade any of the currencies supported by Ekonnet or over 100 other IOUs on the XRP Ledger 
						decentralized exchange. Explore and analyze the markets.The new Trade page works for you whether you're a beginner or 
						a seasoned trader.</p>
						<p>An intuitive interface for trading all your assets. Trade any of the currencies supported by Ekonnet or over 100 other
							IOUs on the XRP Ledger decentralized exchange. Explore and analyze the markets.The new Trade page works for you 
							whether you're a beginner or a seasoned trader.</p>
							<a href="#">Read more</a>
					</div>
					<div className="col-sm-6">
						<img src={trade}/>
					</div>
				</div>
			</div>
		</div>	
	</section>
	<section className="trade-section">
		<div className="trade">
			<div className="container">
				<div className="row">
					<div className="col-sm-6">
						<img src={ana}/>
					</div>
					<div className="col-sm-6">
						<h3>Analytics</h3>
						<p>An intuitive interface for trading all your assets. Trade any of the currencies supported by Ekonnet or over 100 other IOUs on the XRP Ledger 
						decentralized exchange. Explore and analyze the markets.The new Trade page works for you whether you're a beginner or 
						a seasoned trader.</p>
						<p>An intuitive interface for trading all your assets. Trade any of the currencies supported by Ekonnet or over 100 other
							IOUs on the XRP Ledger decentralized exchange. Explore and analyze the markets.The new Trade page works for you 
							whether you're a beginner or a seasoned trader.</p>
							<a href="#">Read more</a>
					</div>
					
				</div>
			</div>
		</div>	
	</section>
	<section className="trade-section">
		<div className="trade">
			<div className="container">
				<div className="row">
					<div className="col-sm-6">
						<h3>Peer-To-Peer</h3>
						<p>An intuitive interface for trading all your assets. Trade any of the currencies supported by Ekonnet or over 100 other IOUs on the XRP Ledger 
						decentralized exchange. Explore and analyze the markets.The new Trade page works for you whether you're a beginner or 
						a seasoned trader.</p>
						<p>An intuitive interface for trading all your assets. Trade any of the currencies supported by Ekonnet or over 100 other
							IOUs on the XRP Ledger decentralized exchange. Explore and analyze the markets.The new Trade page works for you 
							whether you're a beginner or a seasoned trader.</p>
							<a href="#">Read more</a>
					</div>
					<div className="col-sm-6">
						<img src={peer}/>
					</div>
					
					
				</div>
			</div>
		</div>	
	</section>
	<section className="trade-section">
		<div className="trade">
			<div className="container">
				<div className="row">
					<div className="col-sm-6">
						<img src={invest}/>
					</div>
					<div className="col-sm-6">
						<h3>Easy To Invest</h3>
						<p>An intuitive interface for trading all your assets. Trade any of the currencies supported by Ekonnet or over 100 other IOUs on the XRP Ledger 
						decentralized exchange. Explore and analyze the markets.The new Trade page works for you whether you're a beginner or 
						a seasoned trader.</p>
						<p>An intuitive interface for trading all your assets. Trade any of the currencies supported by Ekonnet or over 100 other
							IOUs on the XRP Ledger decentralized exchange. Explore and analyze the markets.The new Trade page works for you 
							whether you're a beginner or a seasoned trader.</p>
							<a href="#">Read more</a>
					</div>
					
					
				</div>
			</div>
		</div>	
	</section>
	<section>
		<div className="trade">
			<div className="container">
				<h3 className="worldclassName">World classNameTradingPlatform</h3>
				<div className="row gy-4">
					<div className="col-sm-4">
						<div className="exchng">
							<img src={exch}/>
							<h4>Exchange</h4>
							<p>Ekonnet offers order books with top tier liquidity, allowing users to easily exchange Bitcoin,
								Ethereum, EOS, Litecoin, Ripple, NEO and many other digital assets with minimal slippage. Ekonnet
								also boasts a suite of order types to help traders take advantage of every situation</p>
						</div>		
					</div>	
					<div className="col-sm-4">
						<div className="exchng">
							<img src={exch}/>
							<h4>Exchange</h4>
							<p>Ekonnet offers order books with top tier liquidity, allowing users to easily exchange Bitcoin,
								Ethereum, EOS, Litecoin, Ripple, NEO and many other digital assets with minimal slippage. Ekonnet
								also boasts a suite of order types to help traders take advantage of every situation</p>
						</div>		
					</div>	
					<div className="col-sm-4">
						<div className="exchng">
							<img src={exch}/>
							<h4>Exchange</h4>
							<p>Ekonnet offers order books with top tier liquidity, allowing users to easily exchange Bitcoin,
								Ethereum, EOS, Litecoin, Ripple, NEO and many other digital assets with minimal slippage. Ekonnet
								also boasts a suite of order types to help traders take advantage of every situation</p>
						</div>		
					</div>
					<div className="col-sm-4">
						<div className="exchng">
							<img src={exch}/>
							<h4>Exchange</h4>
							<p>Ekonnet offers order books with top tier liquidity, allowing users to easily exchange Bitcoin,
								Ethereum, EOS, Litecoin, Ripple, NEO and many other digital assets with minimal slippage. Ekonnet
								also boasts a suite of order types to help traders take advantage of every situation</p>
						</div>		
					</div>	
					<div className="col-sm-4">
						<div className="exchng">
							<img src={exch}/>
							<h4>Exchange</h4>
							<p>Ekonnet offers order books with top tier liquidity, allowing users to easily exchange Bitcoin,
								Ethereum, EOS, Litecoin, Ripple, NEO and many other digital assets with minimal slippage. Ekonnet
								also boasts a suite of order types to help traders take advantage of every situation</p>
						</div>		
					</div>	
					<div className="col-sm-4">
						<div className="exchng">
							<img src={exch}/>
							<h4>Exchange</h4>
							<p>Ekonnet offers order books with top tier liquidity, allowing users to easily exchange Bitcoin,
								Ethereum, EOS, Litecoin, Ripple, NEO and many other digital assets with minimal slippage. Ekonnet
								also boasts a suite of order types to help traders take advantage of every situation</p>
						</div>		
					</div>
				</div>
			</div>
		</div>	
	</section>
	<section className="trade-section">
		<div className="trade">
			<div className="container">
				<div className="row">
					<div className="col-sm-5">
						<div className="mobile-app">
							<img src={mobile}/>
						</div>	
					</div>
					<div className="col-sm-7">
						<div className="mobile-apps">
							<h3>Trusted Platform Anytime & Anywhere</h3>
							<p>The Ekonnet mobile app adapts the full functionality of the Ekonnet platform for seamless ‘on-the-go’ trading.
								The Ekonnet Mobile application allows you to connect to your ekonnet trading account and provide live information
								about your open orders as well as the status of your account. Wherever you are, you can easily make changes to
								your trades and never have to miss an opportunity again.</p>
							<p>The dawn of a better world that is freer. Send or receive any amount of money from anywhere on the planet!</p>
							<div className="row">
								<div className="col-sm-4">
									<a href="#"><img src={download}/></a>
								</div>
								<div className="col-sm-4">
									<a href="#"><img src={downloads}/></a>
								</div>
								<div className="col-sm-4">
									
								</div>
							</div>
						</div>		
					</div>
					
					
				</div>
			</div>
		</div>
	</section>
	<div className="trade">
		<div className="container">
			<div className="crypto-invest">
				<h3>Get started in a few minutes</h3>
				<p>Start your crypto investment with Ekonnet now !</p>
			</div>	
			<div className="row gy-4">
				<div className="col-sm-4">
					<div className="exchng">
						<div className="svg-dv">
							<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" className="icons" height="4rem" 
							width="4rem" xmlns="http://www.w3.org/2000/svg"><path d="M8 0c-.176 0-.35.006-.523.017l.064.998a7.117 7.117 0 0 1
						 	.918 0l.064-.998A8.113 8.113 0 0 0 8 0zM6.44.152c-.346.069-.684.16-1.012.27l.321.948c.287-.098.582-.177.884-.237L6.44.153zm4.132.271a7.946 7.946 0 0 0-1.011-.27l-.194.98c.302.06.597.14.884.237l.321-.947zm1.873.925a8
						 	 8 0 0 0-.906-.524l-.443.896c.275.136.54.29.793.459l.556-.831zM4.46.824c-.314.155-.616.33-.905.524l.556.83a7.07 7.07 0 0 1 
						 	 .793-.458L4.46.824zM2.725 1.985c-.262.23-.51.478-.74.74l.752.66c.202-.23.418-.446.648-.648l-.66-.752zm11.29.74a8.058 8.058 
						  	0 0 0-.74-.74l-.66.752c.23.202.447.418.648.648l.752-.66zm1.161
						  	 1.735a7.98 7.98 0 0 0-.524-.905l-.83.556c.169.253.322.518.458.793l.896-.443zM1.348 3.555c-.194.289-.37.591-.524.906l.896.443c.136-.275.29-.54.459-.793l-.831-.556zM.423 5.428a7.945 7.945 0 0 0-.27 1.011l.98.194c.06-.302.14-.597.237-.884l-.947-.321zM15.848 6.44a7.943
						    7.943 0 0 0-.27-1.012l-.948.321c.098.287.177.582.237.884l.98-.194zM.017 7.477a8.113 8.113 0 0 0 0 1.046l.998-.064a7.117 7.117 0 0 1 0-.918l-.998-.064zM16 8a8.1 8.1 0 0 0-.017-.523l-.998.064a7.11 7.11 0 0 1 0 .918l.998.064A8.1 8.1 0 0 0 16 8zM.152 9.56c.069.346.16.684.27 1.012l.948-.321a6.944 6.944 0 0 1-.237-.884l-.98.194zm15.425 1.012c.112-.328.202-.666.27-1.011l-.98-.194c-.06.302-.14.597-.237.884l.947.321zM.824 11.54a8 8 0 0 0 .524.905l.83-.556a6.999 6.999 0 0 1-.458-.793l-.896.443zm13.828.905c.194-.289.37-.591.524-.906l-.896-.443c-.136.275-.29.54-.459.793l.831.556zm-12.667.83c.23.262.478.51.74.74l.66-.752a7.047 7.047 0 0 1-.648-.648l-.752.66zm11.29.74c.262-.23.51-.478.74-.74l-.752-.66c-.201.23-.418.447-.648.648l.66.752zm-1.735 1.161c.314-.155.616-.33.905-.524l-.556-.83a7.07 7.07 0 0 1-.793.458l.443.896zm-7.985-.524c.289.194.591.37.906.524l.443-.896a6.998 6.998 0 0 1-.793-.459l-.556.831zm1.873.925c.328.112.666.202 1.011.27l.194-.98a6.953 6.953 0 0 1-.884-.237l-.321.947zm4.132.271a7.944 7.944 0 0 0 1.012-.27l-.321-.948a6.954 6.954 0 0 1-.884.237l.194.98zm-2.083.135a8.1 8.1 0 0 0 1.046 0l-.064-.998a7.11 7.11 0 0 1-.918 0l-.064.998zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"></path></svg>
						</div>
							<h4>Create an account</h4>
						<p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consectetur, iste?</p>
					</div>		
				</div>	
				<div className="col-sm-4">
					<div className="exchng">
						<div className="svg-dv">
							<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" className="icons" height="4rem" width="4rem" xmlns="http://www.w3.org/2000/svg"><path d="M894 462c30.9 0 43.8-39.7 18.7-58L530.8 126.2a31.81 31.81 0 0 0-37.6 0L111.3 404c-25.1 18.2-12.2 58 18.8 58H192v374h-72c-4.4 0-8 3.6-8 8v52c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-52c0-4.4-3.6-8-8-8h-72V462h62zM512 196.7l271.1 197.2H240.9L512 196.7zM264 462h117v374H264V462zm189 0h117v374H453V462zm307 374H642V462h118v374z"></path></svg>
						</div>
						<h4>Link your bank account</h4>
						<p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consectetur, iste?</p>
					</div>		
				</div>	
				<div className="col-sm-4">
					<div className="exchng">
						<div className="svg-dv">
							<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" className="icons" height="4rem" width="4rem" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M21 8c-1.45 0-2.26 1.44-1.93 2.51l-3.55 3.56c-.3-.09-.74-.09-1.04 0l-2.55-2.55C12.27 10.45 11.46 9 10 9c-1.45 0-2.27 1.44-1.93 2.52l-4.56 4.55C2.44 15.74 1 16.55 1 18c0 1.1.9 2 2 2 1.45 0 2.26-1.44 1.93-2.51l4.55-4.56c.3.09.74.09 1.04 0l2.55 2.55C12.73 16.55 13.54 18 15 18c1.45 0 2.27-1.44 1.93-2.52l3.56-3.55c1.07.33 2.51-.48 2.51-1.93 0-1.1-.9-2-2-2z"></path><path d="M15 9l.94-2.07L18 6l-2.06-.93L15 3l-.92 2.07L12 6l2.08.93zM3.5 11L4 9l2-.5L4 8l-.5-2L3 8l-2 .5L3 9z"></path></svg>
						</div>
							<h4>Start buying & selling</h4>
						<p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consectetur, iste?</p>
					</div>		
				</div>
			</div>
		</div>
	</div>

	<div className="trade">
		<div className="container">
			<div className="crypto-invest">
				<h3>Join Our Community</h3>
				<p>Ekonnet is Global .Join The Conversation in any of our woldwide communities.</p>
			</div>	
			<div className="row socials">
				<div className="col-sm-3">
					<a href=""><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 320 512" className="community-icon sm:size-1rem md:size-1.2rem lg:size-1.5rem" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path></svg> Facebook</a>
				</div>
				<div className="col-sm-3">
					<a href=""><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" className="community-icon sm:size-1rem md:size-1.2rem lg:size-1.5rem " height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"></path></svg> Twitter</a>
				</div>
				<div className="col-sm-3">
					<a href=""><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="community-icon sm:size-1rem md:size-1.2rem lg:size-1.5rem" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M446.6 222.7c-1.8-8-6.8-15.4-12.5-18.5-1.8-1-13-2.2-25-2.7-20.1-.9-22.3-1.3-28.7-5-10.1-5.9-12.8-12.3-12.9-29.5-.1-33-13.8-63.7-40.9-91.3-19.3-19.7-40.9-33-65.5-40.5-5.9-1.8-19.1-2.4-63.3-2.9-69.4-.8-84.8.6-108.4 10C45.9 59.5 14.7 96.1 3.3 142.9 1.2 151.7.7 165.8.2 246.8c-.6 101.5.1 116.4 6.4 136.5 15.6 49.6 59.9 86.3 104.4 94.3 14.8 2.7 197.3 3.3 216 .8 32.5-4.4 58-17.5 81.9-41.9 17.3-17.7 28.1-36.8 35.2-62.1 4.9-17.6 4.5-142.8 2.5-151.7zm-322.1-63.6c7.8-7.9 10-8.2 58.8-8.2 43.9 0 45.4.1 51.8 3.4 9.3 4.7 13.4 11.3 13.4 21.9 0 9.5-3.8 16.2-12.3 21.6-4.6 2.9-7.3 3.1-50.3 3.3-26.5.2-47.7-.4-50.8-1.2-16.6-4.7-22.8-28.5-10.6-40.8zm191.8 199.8l-14.9 2.4-77.5.9c-68.1.8-87.3-.4-90.9-2-7.1-3.1-13.8-11.7-14.9-19.4-1.1-7.3 2.6-17.3 8.2-22.4 7.1-6.4 10.2-6.6 97.3-6.7 89.6-.1 89.1-.1 97.6 7.8 12.1 11.3 9.5 31.2-4.9 39.4z"></path></svg> Blog</a>
				</div>
				<div className="col-sm-3">
					<a href=""><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" className="community-icon sm:size-1rem md:size-1.2rem lg:size-1.5rem" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M512 378.7c-73.4 0-133.3 59.9-133.3 133.3S438.6 645.3 512 645.3 645.3 585.4 645.3 512 585.4 378.7 512 378.7zM911.8 512c0-55.2.5-109.9-2.6-165-3.1-64-17.7-120.8-64.5-167.6-46.9-46.9-103.6-61.4-167.6-64.5-55.2-3.1-109.9-2.6-165-2.6-55.2 0-109.9-.5-165 2.6-64 3.1-120.8 17.7-167.6 64.5C132.6 226.3 118.1 283 115 347c-3.1 55.2-2.6 109.9-2.6 165s-.5 109.9 2.6 165c3.1 64 17.7 120.8 64.5 167.6 46.9 46.9 103.6 61.4 167.6 64.5 55.2 3.1 109.9 2.6 165 2.6 55.2 0 109.9.5 165-2.6 64-3.1 120.8-17.7 167.6-64.5 46.9-46.9 61.4-103.6 64.5-167.6 3.2-55.1 2.6-109.8 2.6-165zM512 717.1c-113.5 0-205.1-91.6-205.1-205.1S398.5 306.9 512 306.9 717.1 398.5 717.1 512 625.5 717.1 512 717.1zm213.5-370.7c-26.5 0-47.9-21.4-47.9-47.9s21.4-47.9 47.9-47.9 47.9 21.4 47.9 47.9a47.84 47.84 0 0 1-47.9 47.9z"></path></svg> Instagram</a>
				</div>
			</div>
		</div>
	</div>	
    </div>
  );
};

export default Home;
