import React from 'react';
import { IoMdLock } from "react-icons/io";
import './Register.css'; // Assuming the same CSS file is used for styling

const ForgotPassword = () => {
  return (
    <div className="signup-container" style={{height:"70vh"}}>
      <form className="signup-form" style={{width:"400px"}}>
        <div className="lock-container">
          <IoMdLock className="lock-icon" />
        </div>
        <h2>Forgot Password</h2>
        <div className="input-container">
          <input
            type='text'
            placeholder='Mobile Number/ Email*'
            className="input-field"
          />
          <label className="input-label">Mobile Number/ Email*</label>
        </div>
        <button className="signup-button">SUBMIT</button>
      </form>
    </div>
  );
}

export default ForgotPassword;
