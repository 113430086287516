import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-sm-2">
            <h3>Services & Support</h3>
            <ul>
              <li><Link to="#">Exchange</Link></li>
              <li><Link to="#">Otc</Link></li>
              <li><Link to="#">Staking</Link></li>
              <li><Link to="#">Security & Protection</Link></li>
              <li><Link to="#">Credit & Debit Card</Link></li>
              <li><Link to="#">Contact Us</Link></li>
              <li><Link to="#">Help Center</Link></li>
              <li><Link to="#">Bug Bounty</Link></li>
              <li><Link to="#">FAQs</Link></li>
            </ul>
          </div>
          <div className="col-sm-2">
            <h3>Products</h3>
            <ul>
              <li><Link to="#">Exchange Trades</Link></li>
              <li><Link to="#">Margin Trades</Link></li>
              <li><Link to="#">Margin Funding</Link></li>
              <li><Link to="#">Blog</Link></li>
              <li><Link to="#">Mobile App</Link></li>
              <li><Link to="#">Ekonnet Pay</Link></li>
              <li><Link to="#">Wallet</Link></li>
              <li><Link to="#">Tell-a-Friend</Link></li>
              <li><Link to="#">Best</Link></li>
            </ul>
          </div>
          <div className="col-sm-2">
            <h3>Company</h3>
            <ul>
              <li><Link to="#">About Us</Link></li>
              <li><Link to="#">Affiliate</Link></li>
              <li><Link to="#">Career</Link></li>
              <li><Link to="#">NewsLetter</Link></li>
              <li><Link to="#">Fees</Link></li>
              <li><Link to="#">Exchange</Link></li>
              <li><Link to="#">Exchange</Link></li>
            </ul>
          </div>
          <div className="col-sm-2">
            <h3>Read</h3>
            <ul>
              <li><Link to="#">Paper Trading</Link></li>
              <li><Link to="#">How to buy Bitcoin</Link></li>
              <li><Link to="#">How to buy Tether</Link></li>
              <li><Link to="#">How to buy Ethereum</Link></li>
              <li><Link to="#">How to buy XRP</Link></li>
              <li><Link to="#">How to buy Shiba Inu</Link></li>
              <li><Link to="#">How to buy Gold</Link></li>
              <li><Link to="#">How to buy Silver</Link></li>
            </ul>
          </div>
          <div className="col-sm-2">
            <h3>Legal & Privacy</h3>
            <ul>
              <li><Link to="#">General Notices & Terms</Link></li>
              <li><Link to="#">Ekonnet Pay Terms</Link></li>
              <li><Link to="#">Exchange Terms</Link></li>
              <li><Link to="#">Derivative Terms</Link></li>
              <li><Link to="#">Privacy</Link></li>
              <li><Link to="#">Cookies Policy</Link></li>
              <li><Link to="#">Cookies Preferences</Link></li>
              <li><Link to="#">Token Specific Terms</Link></li>
              <li><Link to="#">Trading Rulebook</Link></li>
            </ul>
          </div>
          <div className="col-sm-2">
            <h3>Multi-Trades Orders</h3>
            <ul>
              <li><Link to="#">Market Order</Link></li>
              <li><Link to="#">Limit Order</Link></li>
              <li><Link to="#">Stop Order</Link></li>
              <li><Link to="#">Stop limit Order</Link></li>
              <li><Link to="#">Stop limit Order</Link></li>
              <li><Link to="#">Fill or Kill Order</Link></li>
              <li><Link to="#">Immediate Cancellation Order</Link></li>
              <li><Link to="#">Trailing Limit Order</Link></li>
              <li><Link to="#">Active Book Order</Link></li>
              <li><Link to="#">Scaled Pro Order</Link></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="copyright">
        <p>&copy; Copyright 2024 Your Company. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
